import { Globals } from '@busy-human/gearbox';
import { initializeApp, FirebaseOptions } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, updateDoc, doc } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';

const firebaseOptions: FirebaseOptions = CONFIG.firebase.config;
const app = initializeApp( firebaseOptions );
export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const functions = getFunctions(app);
export const storage = getStorage(app);
export const analytics = getAnalytics(app);

Globals.setFirestore(firestore);

console.log("Firebase Initialized");

export { updateDoc, doc };