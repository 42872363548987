import { defineComponent as _defineComponent } from 'vue'
import { onMounted } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'app',
  setup(__props, { expose: __expose }) {
  __expose();

// import router from "../router";
onMounted(() => {
	(window as any).APPLICATION_LOADER.done();
});

const __returned__ = { onMounted }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})