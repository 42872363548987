import "core-js/stable";
import "regenerator-runtime/runtime"; 

import "./stylesheets/app.scss";
import "@busy-human/vue-component-library/style";

import "@/util/firebase";

import events from "./events";
import { initEvents, VueModal } from "@busy-human/vue-component-library";
initEvents(events);

import app from "./app";

import { initRaygun } from "@busy-human/raygun-util";
if(!CONFIG.raygun.apiKey) {
    console.warn("raygun apikey not set");
} else {
    initRaygun(CONFIG.raygun.apiKey, { Vue: app, version: VERSION, environment: ENVIRONMENT});
}

import { createPinia } from "pinia";
const pinia = createPinia();
app.use(pinia);

app.component("VModal", VueModal);

import router from "./router";
app.use(router);
app.mount("#application-root");