import {createRouter, createWebHashHistory} from "vue-router";
import { useAuth } from "./store/auth";
import { useEntitlements } from "./store/entitlements";


// Routes are lazy loaded (README: Vue Router > Lazy Loading)
const HomeView = () => import("./views/home.vue");
const AuthView = () => import("./views/auth.vue");

const DashboardView = () => import("./views/dashboard.vue");

const ContentCalendarView = () => import("./views/content-calendar.vue");

const ContentCardView = () => import("./views/content-card-page.vue");
const ContentView = () => import("./views/content-page.vue");

const AccountView = () => import("./views/account.vue");
const TestView = () => import("./views/test.vue");

const router = createRouter({
    // HTML5 history, no more hash # (README: Vue Router > URL Structure)
    history: createWebHashHistory(),
    routes: [
        {
            path: "/login",
            component: AuthView,
            name: "login",
            meta: {requiresAuth: false},
        },

        { 
            path: "/",  
            component: HomeView, 
            name: "home", 
            redirect: "/dashboard",
            meta: {requiresAuth: true,},
            children: [
                {
                    path: 'dashboard',
                    component: DashboardView,
                    name: 'Dashboard',
                    meta: {requiresSubscription: true},
                },
                {
                    path: 'content-calendar',
                    component: ContentCalendarView,
                    name: 'Content Calendar',
                    meta: {requiresSubscription: true},
                },
                {
                    path: 'content-vault',
                    component: ContentCardView,
                    name: 'Content Vault',
                    meta: {requiresSubscription: true},
                    children: [
                        {
                            path: ':id',
                            component: ContentView,
                            name: "Content Vault Detail",
                            props: true,
                            meta: {requiresSubscription: true}
                        }
                    ]
                },
                {
                    path: 'agent-tools',
                    component: ContentCardView,
                    name: 'Agent Tools',
                    meta: {requiresSubscription: true},
                    children: [
                        {
                            path: ':id',
                            component: ContentView,
                            name: "Agent Tools Detail",
                            props: true,
                            meta: {requiresSubscription: true}
                        }
                    ]
                },
                {
                    path: 'digital-marketing',
                    component: ContentCardView,
                    name: 'Digital Marketing',
                    meta: {requiresSubscription: true},
                    children: [
                        {
                            path: ':id',
                            component: ContentView,
                            name: "Digital Marketing Detail",
                            props: true,
                            meta: {requiresSubscription: true}
                        }
                    ]
                },
                {
                    path: 'print',
                    component: ContentCardView,
                    name: 'Print Templates',
                    meta: {requiresSubscription: true},
                    children: [
                        {
                            path: ':id',
                            component: ContentView,
                            name: "Print Templates Detail",
                            props: true,
                            meta: {requiresSubscription: true}
                        }
                    ]
                },
                {
                    path: 'informational',
                    component: ContentCardView,
                    name: 'Informational',
                    meta: {requiresSubscription: true},
                    children: [
                        {
                            path: ':id',
                            component: ContentView,
                            name: "Informational Detail",
                            props: true,
                            meta: {requiresSubscription: true}
                        }
                    ]
                },
                {
                    path: 'support',
                    component: AccountView,
                    name: 'Support'
                },
                {
                    path: 'account',
                    component: AccountView,
                    name: 'Account'
                },
            ]
        },
    ]
});

router.beforeEach(async (to) => {
    const auth = useAuth();
    const entitlements = useEntitlements();
    await auth.waitForReady();

    if(to.meta.requiresAuth === true && !auth.user) return 'login';
    else if(to.name === 'login' && auth.user) return '/dashboard';

    if(to.meta.requiresSubscription){
        await entitlements.waitForReady();
        if(!entitlements.userIsSubscribed){
            console.log("User Should not be able to access this page");
            return 'account';
        }
    }
});

export default router;

