import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, Transition as _Transition, withCtx as _withCtx, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "inset-gutter-10px",
  id: "app"
}
const _hoisted_2 = { class: "no-gutter" }
const _hoisted_3 = { class: "view-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("main", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_view, null, {
          default: _withCtx(({ Component, route }: any) => [
            (route.meta.transition)
              ? (_openBlock(), _createBlock(_Transition, {
                  key: 0,
                  name: route.meta.transition,
                  mode: "in-out"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(), _createBlock(_KeepAlive, null, [
                      (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                        key: route.meta.usePathKey ? route.path : undefined
                      }))
                    ], 1024))
                  ]),
                  _: 2
                }, 1032, ["name"]))
              : _createCommentVNode("", true),
            (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
              key: route.meta.usePathKey ? route.path : undefined
            }))
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("footer", null, [
      _createElementVNode("span", null, "Copyright ©" + _toDisplayString(_ctx.year) + " Busy Human LLC, All Rights Reserved", 1)
    ])
  ]))
}