var zt = Object.defineProperty;
var Ht = (e, t, a) => t in e ? zt(e, t, { enumerable: !0, configurable: !0, writable: !0, value: a }) : e[t] = a;
var k = (e, t, a) => (Ht(e, typeof t != "symbol" ? t + "" : t, a), a);
import { onMounted as H, onUnmounted as ge, ref as g, computed as G, onUpdated as Yt, watchEffect as Jt, openBlock as c, createElementBlock as p, createElementVNode as d, normalizeClass as N, withDirectives as V, vModelText as W, toDisplayString as C, vShow as qt, defineComponent as L, createCommentVNode as y, isRef as xt, unref as M, renderSlot as B, createVNode as oe, Fragment as J, renderList as Q, Transition as Qt, withCtx as gt, createBlock as Xt, resolveDynamicComponent as Zt, withModifiers as ea, withKeys as ta, resolveComponent as aa, watch as je, vModelRadio as sa, normalizeStyle as mt } from "vue";
import { throttle as ra } from "lodash-es";
import na from "zxcvbn";
import Y from "mitt";
import { signInWithEmailAndPassword as ia, createUserWithEmailAndPassword as oa, sendPasswordResetEmail as la, onAuthStateChanged as ua } from "firebase/auth";
function ff(e, t) {
  function a(s) {
    e.value && !(e.value === s.target || e.value.contains(s.target)) && t(s);
  }
  H(() => {
    document.body.addEventListener("click", a);
  }), ge(() => {
    document.body.removeEventListener("click", a);
  });
}
const da = {
  mobile: [{ maxWidth: 1e3 }],
  desktop: [{ minWidth: 1001 }]
};
function hf(e = da) {
  const t = g(window.innerWidth), a = g(window.innerHeight), s = G(() => {
    for (const n in e)
      if (e[n].reduce((o, l) => l.maxWidth && t.value <= l.maxWidth || l.minWidth && t.value >= l.minWidth || l.maxHeight && a.value <= l.maxHeight || l.minHeight && a.value >= l.minHeight ? o + 1 : o, 0) === e[n].length)
        return n;
    return Object.keys(e)[0];
  }), i = ra(() => {
    t.value = window.innerWidth, a.value = window.innerHeight;
  }, 200);
  return H(() => {
    window.addEventListener("resize", i);
  }), ge(() => {
    window.removeEventListener("resize", i);
  }), { width: t, height: a, sizeClass: s };
}
function vf(e, t, a) {
  H(() => {
    e.on(t, a);
  }), ge(() => {
    e.off(t);
  });
}
function De(e, { emit: t, mode: a = "login", minPasswordScore: s }) {
  const i = g(""), n = g(""), r = g(""), o = g(""), l = g(null), u = g({
    username: !1,
    password: !1,
    confirmPassword: !1
  }), h = g(null), m = g(!1), S = g(!1), w = g(!1), D = Y(), T = G(() => r ? r.value.length : 0), j = G(() => {
    switch (a) {
      case "login":
        return u.value.username && u.value.password;
      case "signup":
        return u.value.username && u.value.password && u.value.confirmPassword;
      case "reset":
        return u.value.password;
    }
  }), E = G(() => S.value ? "Logging in..." : "SIGN IN");
  function Z() {
    var f;
    u.value.username = !!((f = i.value.match(/([\w_\-\.]*)@([_\-\.\w]+\.[_\-\w]{2,})/g)) != null && f[0]);
  }
  function q() {
    if (a === "signup") {
      var f = na(r.value).score;
      u.value.password = f >= s;
    } else
      u.value.password = r.value.length >= 7;
  }
  function P() {
    u.value.confirmPassword = o.value.length > 4 && r.value == o.value;
  }
  function O() {
    Z(), q();
    let f = "";
    i.value.length > 0 && !u.value.username ? (f = "Username must be an email address", m.value = !0) : r.value.length > 0 && !u.value.password ? (f = "Password must be at least 7 characters long", m.value = !0) : m.value = !1, f ? h.value = "* " + f : h.value = null;
  }
  function F() {
    return R(O);
  }
  function R(f) {
    l.value && (clearTimeout(l.value), l.value = null), l.value = setTimeout(() => {
      l.value = null, f();
    }, 750);
  }
  async function $() {
    var f;
    S.value = !0;
    try {
      const b = await ia(e, i.value, r.value);
      console.log("Login successful!"), S.value = !1, t("login", b), D.emit("login", b);
    } catch (b) {
      if (b instanceof Error) {
        if ((f = b.stack) != null && f.includes("Uncaught DOMException: Failed to execute 'put' on")) {
          S.value = !1, console.log("AUTH ERR", typeof b, b.value, Object.keys(b), JSON.stringify(b), b);
          return;
        }
        m.value = !0, b.value = "* Incorrect Email Or Password", t("error", b), D.emit("error", b), S.value = !1;
      }
    }
  }
  async function _() {
    try {
      const f = await oa(e, i.value, r.value);
      alert("Account created!"), t("signup", f), D.emit("signup", f);
    } catch (f) {
      alert(f), t("error", f), D.emit("error", f);
    }
  }
  async function v() {
    if (!w.value) {
      w.value = !0;
      var f = () => {
        setTimeout(() => {
          w.value = !1;
        }, 5e3);
      };
      try {
        const b = await la(e, n.value);
        alert("Reset email sent"), t("reset", b), f();
      } catch (b) {
        alert(b), t("error", b), f();
      }
    }
  }
  return {
    emitter: D,
    email: n,
    username: i,
    password: r,
    confirmPassword: o,
    validationTimeout: l,
    validation: u,
    error: h,
    showError: m,
    loggingIn: S,
    processing: w,
    passwordLength: T,
    formValid: j,
    loginButtonLabel: E,
    validateWithDelay: R,
    validateInputWithDelay: F,
    validateUsername: Z,
    validatePassword: q,
    validateConfirmPassword: P,
    checkValidInput: O,
    onTapLogin: $,
    onTapCreate: _,
    onTapReset: v
  };
}
var de = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function ca(e) {
  return e && e.__esModule && Object.prototype.hasOwnProperty.call(e, "default") ? e.default : e;
}
function pa() {
  this.__data__ = [], this.size = 0;
}
var fa = pa;
function ha(e, t) {
  return e === t || e !== e && t !== t;
}
var yt = ha, va = yt;
function ga(e, t) {
  for (var a = e.length; a--; )
    if (va(e[a][0], t))
      return a;
  return -1;
}
var me = ga, ma = me, ya = Array.prototype, ba = ya.splice;
function $a(e) {
  var t = this.__data__, a = ma(t, e);
  if (a < 0)
    return !1;
  var s = t.length - 1;
  return a == s ? t.pop() : ba.call(t, a, 1), --this.size, !0;
}
var _a = $a, wa = me;
function Ta(e) {
  var t = this.__data__, a = wa(t, e);
  return a < 0 ? void 0 : t[a][1];
}
var ka = Ta, Ca = me;
function Sa(e) {
  return Ca(this.__data__, e) > -1;
}
var Aa = Sa, Ia = me;
function Pa(e, t) {
  var a = this.__data__, s = Ia(a, e);
  return s < 0 ? (++this.size, a.push([e, t])) : a[s][1] = t, this;
}
var Oa = Pa, ja = fa, Da = _a, Ea = ka, Ma = Aa, La = Oa;
function te(e) {
  var t = -1, a = e == null ? 0 : e.length;
  for (this.clear(); ++t < a; ) {
    var s = e[t];
    this.set(s[0], s[1]);
  }
}
te.prototype.clear = ja;
te.prototype.delete = Da;
te.prototype.get = Ea;
te.prototype.has = Ma;
te.prototype.set = La;
var ye = te, Fa = ye;
function Na() {
  this.__data__ = new Fa(), this.size = 0;
}
var Ra = Na;
function Va(e) {
  var t = this.__data__, a = t.delete(e);
  return this.size = t.size, a;
}
var Ua = Va;
function Ba(e) {
  return this.__data__.get(e);
}
var Ga = Ba;
function Wa(e) {
  return this.__data__.has(e);
}
var Ka = Wa, za = typeof de == "object" && de && de.Object === Object && de, bt = za, Ha = bt, Ya = typeof self == "object" && self && self.Object === Object && self, Ja = Ha || Ya || Function("return this")(), K = Ja, qa = K, xa = qa.Symbol, Ee = xa, Ke = Ee, $t = Object.prototype, Qa = $t.hasOwnProperty, Xa = $t.toString, ie = Ke ? Ke.toStringTag : void 0;
function Za(e) {
  var t = Qa.call(e, ie), a = e[ie];
  try {
    e[ie] = void 0;
    var s = !0;
  } catch {
  }
  var i = Xa.call(e);
  return s && (t ? e[ie] = a : delete e[ie]), i;
}
var es = Za, ts = Object.prototype, as = ts.toString;
function ss(e) {
  return as.call(e);
}
var rs = ss, ze = Ee, ns = es, is = rs, os = "[object Null]", ls = "[object Undefined]", He = ze ? ze.toStringTag : void 0;
function us(e) {
  return e == null ? e === void 0 ? ls : os : He && He in Object(e) ? ns(e) : is(e);
}
var be = us;
function ds(e) {
  var t = typeof e;
  return e != null && (t == "object" || t == "function");
}
var le = ds, cs = be, ps = le, fs = "[object AsyncFunction]", hs = "[object Function]", vs = "[object GeneratorFunction]", gs = "[object Proxy]";
function ms(e) {
  if (!ps(e))
    return !1;
  var t = cs(e);
  return t == hs || t == vs || t == fs || t == gs;
}
var _t = ms, ys = K, bs = ys["__core-js_shared__"], $s = bs, ke = $s, Ye = function() {
  var e = /[^.]+$/.exec(ke && ke.keys && ke.keys.IE_PROTO || "");
  return e ? "Symbol(src)_1." + e : "";
}();
function _s(e) {
  return !!Ye && Ye in e;
}
var ws = _s, Ts = Function.prototype, ks = Ts.toString;
function Cs(e) {
  if (e != null) {
    try {
      return ks.call(e);
    } catch {
    }
    try {
      return e + "";
    } catch {
    }
  }
  return "";
}
var wt = Cs, Ss = _t, As = ws, Is = le, Ps = wt, Os = /[\\^$.*+?()[\]{}|]/g, js = /^\[object .+?Constructor\]$/, Ds = Function.prototype, Es = Object.prototype, Ms = Ds.toString, Ls = Es.hasOwnProperty, Fs = RegExp(
  "^" + Ms.call(Ls).replace(Os, "\\$&").replace(/hasOwnProperty|(function).*?(?=\\\()| for .+?(?=\\\])/g, "$1.*?") + "$"
);
function Ns(e) {
  if (!Is(e) || As(e))
    return !1;
  var t = Ss(e) ? Fs : js;
  return t.test(Ps(e));
}
var Rs = Ns;
function Vs(e, t) {
  return e == null ? void 0 : e[t];
}
var Us = Vs, Bs = Rs, Gs = Us;
function Ws(e, t) {
  var a = Gs(e, t);
  return Bs(a) ? a : void 0;
}
var X = Ws, Ks = X, zs = K, Hs = Ks(zs, "Map"), Me = Hs, Ys = X, Js = Ys(Object, "create"), $e = Js, Je = $e;
function qs() {
  this.__data__ = Je ? Je(null) : {}, this.size = 0;
}
var xs = qs;
function Qs(e) {
  var t = this.has(e) && delete this.__data__[e];
  return this.size -= t ? 1 : 0, t;
}
var Xs = Qs, Zs = $e, er = "__lodash_hash_undefined__", tr = Object.prototype, ar = tr.hasOwnProperty;
function sr(e) {
  var t = this.__data__;
  if (Zs) {
    var a = t[e];
    return a === er ? void 0 : a;
  }
  return ar.call(t, e) ? t[e] : void 0;
}
var rr = sr, nr = $e, ir = Object.prototype, or = ir.hasOwnProperty;
function lr(e) {
  var t = this.__data__;
  return nr ? t[e] !== void 0 : or.call(t, e);
}
var ur = lr, dr = $e, cr = "__lodash_hash_undefined__";
function pr(e, t) {
  var a = this.__data__;
  return this.size += this.has(e) ? 0 : 1, a[e] = dr && t === void 0 ? cr : t, this;
}
var fr = pr, hr = xs, vr = Xs, gr = rr, mr = ur, yr = fr;
function ae(e) {
  var t = -1, a = e == null ? 0 : e.length;
  for (this.clear(); ++t < a; ) {
    var s = e[t];
    this.set(s[0], s[1]);
  }
}
ae.prototype.clear = hr;
ae.prototype.delete = vr;
ae.prototype.get = gr;
ae.prototype.has = mr;
ae.prototype.set = yr;
var br = ae, qe = br, $r = ye, _r = Me;
function wr() {
  this.size = 0, this.__data__ = {
    hash: new qe(),
    map: new (_r || $r)(),
    string: new qe()
  };
}
var Tr = wr;
function kr(e) {
  var t = typeof e;
  return t == "string" || t == "number" || t == "symbol" || t == "boolean" ? e !== "__proto__" : e === null;
}
var Cr = kr, Sr = Cr;
function Ar(e, t) {
  var a = e.__data__;
  return Sr(t) ? a[typeof t == "string" ? "string" : "hash"] : a.map;
}
var _e = Ar, Ir = _e;
function Pr(e) {
  var t = Ir(this, e).delete(e);
  return this.size -= t ? 1 : 0, t;
}
var Or = Pr, jr = _e;
function Dr(e) {
  return jr(this, e).get(e);
}
var Er = Dr, Mr = _e;
function Lr(e) {
  return Mr(this, e).has(e);
}
var Fr = Lr, Nr = _e;
function Rr(e, t) {
  var a = Nr(this, e), s = a.size;
  return a.set(e, t), this.size += a.size == s ? 0 : 1, this;
}
var Vr = Rr, Ur = Tr, Br = Or, Gr = Er, Wr = Fr, Kr = Vr;
function se(e) {
  var t = -1, a = e == null ? 0 : e.length;
  for (this.clear(); ++t < a; ) {
    var s = e[t];
    this.set(s[0], s[1]);
  }
}
se.prototype.clear = Ur;
se.prototype.delete = Br;
se.prototype.get = Gr;
se.prototype.has = Wr;
se.prototype.set = Kr;
var zr = se, Hr = ye, Yr = Me, Jr = zr, qr = 200;
function xr(e, t) {
  var a = this.__data__;
  if (a instanceof Hr) {
    var s = a.__data__;
    if (!Yr || s.length < qr - 1)
      return s.push([e, t]), this.size = ++a.size, this;
    a = this.__data__ = new Jr(s);
  }
  return a.set(e, t), this.size = a.size, this;
}
var Qr = xr, Xr = ye, Zr = Ra, en = Ua, tn = Ga, an = Ka, sn = Qr;
function re(e) {
  var t = this.__data__ = new Xr(e);
  this.size = t.size;
}
re.prototype.clear = Zr;
re.prototype.delete = en;
re.prototype.get = tn;
re.prototype.has = an;
re.prototype.set = sn;
var rn = re;
function nn(e, t) {
  for (var a = -1, s = e == null ? 0 : e.length; ++a < s && t(e[a], a, e) !== !1; )
    ;
  return e;
}
var on = nn, ln = X, un = function() {
  try {
    var e = ln(Object, "defineProperty");
    return e({}, "", {}), e;
  } catch {
  }
}(), dn = un, xe = dn;
function cn(e, t, a) {
  t == "__proto__" && xe ? xe(e, t, {
    configurable: !0,
    enumerable: !0,
    value: a,
    writable: !0
  }) : e[t] = a;
}
var Tt = cn, pn = Tt, fn = yt, hn = Object.prototype, vn = hn.hasOwnProperty;
function gn(e, t, a) {
  var s = e[t];
  (!(vn.call(e, t) && fn(s, a)) || a === void 0 && !(t in e)) && pn(e, t, a);
}
var kt = gn, mn = kt, yn = Tt;
function bn(e, t, a, s) {
  var i = !a;
  a || (a = {});
  for (var n = -1, r = t.length; ++n < r; ) {
    var o = t[n], l = s ? s(a[o], e[o], o, a, e) : void 0;
    l === void 0 && (l = e[o]), i ? yn(a, o, l) : mn(a, o, l);
  }
  return a;
}
var we = bn;
function $n(e, t) {
  for (var a = -1, s = Array(e); ++a < e; )
    s[a] = t(a);
  return s;
}
var _n = $n;
function wn(e) {
  return e != null && typeof e == "object";
}
var ue = wn, Tn = be, kn = ue, Cn = "[object Arguments]";
function Sn(e) {
  return kn(e) && Tn(e) == Cn;
}
var An = Sn, Qe = An, In = ue, Ct = Object.prototype, Pn = Ct.hasOwnProperty, On = Ct.propertyIsEnumerable, jn = Qe(function() {
  return arguments;
}()) ? Qe : function(e) {
  return In(e) && Pn.call(e, "callee") && !On.call(e, "callee");
}, Dn = jn, En = Array.isArray, Le = En, fe = { exports: {} };
function Mn() {
  return !1;
}
var Ln = Mn;
fe.exports;
(function(e, t) {
  var a = K, s = Ln, i = t && !t.nodeType && t, n = i && !0 && e && !e.nodeType && e, r = n && n.exports === i, o = r ? a.Buffer : void 0, l = o ? o.isBuffer : void 0, u = l || s;
  e.exports = u;
})(fe, fe.exports);
var St = fe.exports, Fn = 9007199254740991, Nn = /^(?:0|[1-9]\d*)$/;
function Rn(e, t) {
  var a = typeof e;
  return t = t ?? Fn, !!t && (a == "number" || a != "symbol" && Nn.test(e)) && e > -1 && e % 1 == 0 && e < t;
}
var Vn = Rn, Un = 9007199254740991;
function Bn(e) {
  return typeof e == "number" && e > -1 && e % 1 == 0 && e <= Un;
}
var At = Bn, Gn = be, Wn = At, Kn = ue, zn = "[object Arguments]", Hn = "[object Array]", Yn = "[object Boolean]", Jn = "[object Date]", qn = "[object Error]", xn = "[object Function]", Qn = "[object Map]", Xn = "[object Number]", Zn = "[object Object]", ei = "[object RegExp]", ti = "[object Set]", ai = "[object String]", si = "[object WeakMap]", ri = "[object ArrayBuffer]", ni = "[object DataView]", ii = "[object Float32Array]", oi = "[object Float64Array]", li = "[object Int8Array]", ui = "[object Int16Array]", di = "[object Int32Array]", ci = "[object Uint8Array]", pi = "[object Uint8ClampedArray]", fi = "[object Uint16Array]", hi = "[object Uint32Array]", I = {};
I[ii] = I[oi] = I[li] = I[ui] = I[di] = I[ci] = I[pi] = I[fi] = I[hi] = !0;
I[zn] = I[Hn] = I[ri] = I[Yn] = I[ni] = I[Jn] = I[qn] = I[xn] = I[Qn] = I[Xn] = I[Zn] = I[ei] = I[ti] = I[ai] = I[si] = !1;
function vi(e) {
  return Kn(e) && Wn(e.length) && !!I[Gn(e)];
}
var gi = vi;
function mi(e) {
  return function(t) {
    return e(t);
  };
}
var Fe = mi, he = { exports: {} };
he.exports;
(function(e, t) {
  var a = bt, s = t && !t.nodeType && t, i = s && !0 && e && !e.nodeType && e, n = i && i.exports === s, r = n && a.process, o = function() {
    try {
      var l = i && i.require && i.require("util").types;
      return l || r && r.binding && r.binding("util");
    } catch {
    }
  }();
  e.exports = o;
})(he, he.exports);
var Ne = he.exports, yi = gi, bi = Fe, Xe = Ne, Ze = Xe && Xe.isTypedArray, $i = Ze ? bi(Ze) : yi, _i = $i, wi = _n, Ti = Dn, ki = Le, Ci = St, Si = Vn, Ai = _i, Ii = Object.prototype, Pi = Ii.hasOwnProperty;
function Oi(e, t) {
  var a = ki(e), s = !a && Ti(e), i = !a && !s && Ci(e), n = !a && !s && !i && Ai(e), r = a || s || i || n, o = r ? wi(e.length, String) : [], l = o.length;
  for (var u in e)
    (t || Pi.call(e, u)) && !(r && // Safari 9 has enumerable `arguments.length` in strict mode.
    (u == "length" || // Node.js 0.10 has enumerable non-index properties on buffers.
    i && (u == "offset" || u == "parent") || // PhantomJS 2 has enumerable non-index properties on typed arrays.
    n && (u == "buffer" || u == "byteLength" || u == "byteOffset") || // Skip index properties.
    Si(u, l))) && o.push(u);
  return o;
}
var It = Oi, ji = Object.prototype;
function Di(e) {
  var t = e && e.constructor, a = typeof t == "function" && t.prototype || ji;
  return e === a;
}
var Re = Di;
function Ei(e, t) {
  return function(a) {
    return e(t(a));
  };
}
var Pt = Ei, Mi = Pt, Li = Mi(Object.keys, Object), Fi = Li, Ni = Re, Ri = Fi, Vi = Object.prototype, Ui = Vi.hasOwnProperty;
function Bi(e) {
  if (!Ni(e))
    return Ri(e);
  var t = [];
  for (var a in Object(e))
    Ui.call(e, a) && a != "constructor" && t.push(a);
  return t;
}
var Gi = Bi, Wi = _t, Ki = At;
function zi(e) {
  return e != null && Ki(e.length) && !Wi(e);
}
var Ot = zi, Hi = It, Yi = Gi, Ji = Ot;
function qi(e) {
  return Ji(e) ? Hi(e) : Yi(e);
}
var Ve = qi, xi = we, Qi = Ve;
function Xi(e, t) {
  return e && xi(t, Qi(t), e);
}
var Zi = Xi;
function eo(e) {
  var t = [];
  if (e != null)
    for (var a in Object(e))
      t.push(a);
  return t;
}
var to = eo, ao = le, so = Re, ro = to, no = Object.prototype, io = no.hasOwnProperty;
function oo(e) {
  if (!ao(e))
    return ro(e);
  var t = so(e), a = [];
  for (var s in e)
    s == "constructor" && (t || !io.call(e, s)) || a.push(s);
  return a;
}
var lo = oo, uo = It, co = lo, po = Ot;
function fo(e) {
  return po(e) ? uo(e, !0) : co(e);
}
var Ue = fo, ho = we, vo = Ue;
function go(e, t) {
  return e && ho(t, vo(t), e);
}
var mo = go, ve = { exports: {} };
ve.exports;
(function(e, t) {
  var a = K, s = t && !t.nodeType && t, i = s && !0 && e && !e.nodeType && e, n = i && i.exports === s, r = n ? a.Buffer : void 0, o = r ? r.allocUnsafe : void 0;
  function l(u, h) {
    if (h)
      return u.slice();
    var m = u.length, S = o ? o(m) : new u.constructor(m);
    return u.copy(S), S;
  }
  e.exports = l;
})(ve, ve.exports);
var yo = ve.exports;
function bo(e, t) {
  var a = -1, s = e.length;
  for (t || (t = Array(s)); ++a < s; )
    t[a] = e[a];
  return t;
}
var $o = bo;
function _o(e, t) {
  for (var a = -1, s = e == null ? 0 : e.length, i = 0, n = []; ++a < s; ) {
    var r = e[a];
    t(r, a, e) && (n[i++] = r);
  }
  return n;
}
var wo = _o;
function To() {
  return [];
}
var jt = To, ko = wo, Co = jt, So = Object.prototype, Ao = So.propertyIsEnumerable, et = Object.getOwnPropertySymbols, Io = et ? function(e) {
  return e == null ? [] : (e = Object(e), ko(et(e), function(t) {
    return Ao.call(e, t);
  }));
} : Co, Be = Io, Po = we, Oo = Be;
function jo(e, t) {
  return Po(e, Oo(e), t);
}
var Do = jo;
function Eo(e, t) {
  for (var a = -1, s = t.length, i = e.length; ++a < s; )
    e[i + a] = t[a];
  return e;
}
var Dt = Eo, Mo = Pt, Lo = Mo(Object.getPrototypeOf, Object), Et = Lo, Fo = Dt, No = Et, Ro = Be, Vo = jt, Uo = Object.getOwnPropertySymbols, Bo = Uo ? function(e) {
  for (var t = []; e; )
    Fo(t, Ro(e)), e = No(e);
  return t;
} : Vo, Mt = Bo, Go = we, Wo = Mt;
function Ko(e, t) {
  return Go(e, Wo(e), t);
}
var zo = Ko, Ho = Dt, Yo = Le;
function Jo(e, t, a) {
  var s = t(e);
  return Yo(e) ? s : Ho(s, a(e));
}
var Lt = Jo, qo = Lt, xo = Be, Qo = Ve;
function Xo(e) {
  return qo(e, Qo, xo);
}
var Zo = Xo, el = Lt, tl = Mt, al = Ue;
function sl(e) {
  return el(e, al, tl);
}
var rl = sl, nl = X, il = K, ol = nl(il, "DataView"), ll = ol, ul = X, dl = K, cl = ul(dl, "Promise"), pl = cl, fl = X, hl = K, vl = fl(hl, "Set"), gl = vl, ml = X, yl = K, bl = ml(yl, "WeakMap"), $l = bl, Ce = ll, Se = Me, Ae = pl, Ie = gl, Pe = $l, Ft = be, ne = wt, tt = "[object Map]", _l = "[object Object]", at = "[object Promise]", st = "[object Set]", rt = "[object WeakMap]", nt = "[object DataView]", wl = ne(Ce), Tl = ne(Se), kl = ne(Ae), Cl = ne(Ie), Sl = ne(Pe), x = Ft;
(Ce && x(new Ce(new ArrayBuffer(1))) != nt || Se && x(new Se()) != tt || Ae && x(Ae.resolve()) != at || Ie && x(new Ie()) != st || Pe && x(new Pe()) != rt) && (x = function(e) {
  var t = Ft(e), a = t == _l ? e.constructor : void 0, s = a ? ne(a) : "";
  if (s)
    switch (s) {
      case wl:
        return nt;
      case Tl:
        return tt;
      case kl:
        return at;
      case Cl:
        return st;
      case Sl:
        return rt;
    }
  return t;
});
var Ge = x, Al = Object.prototype, Il = Al.hasOwnProperty;
function Pl(e) {
  var t = e.length, a = new e.constructor(t);
  return t && typeof e[0] == "string" && Il.call(e, "index") && (a.index = e.index, a.input = e.input), a;
}
var Ol = Pl, jl = K, Dl = jl.Uint8Array, El = Dl, it = El;
function Ml(e) {
  var t = new e.constructor(e.byteLength);
  return new it(t).set(new it(e)), t;
}
var We = Ml, Ll = We;
function Fl(e, t) {
  var a = t ? Ll(e.buffer) : e.buffer;
  return new e.constructor(a, e.byteOffset, e.byteLength);
}
var Nl = Fl, Rl = /\w*$/;
function Vl(e) {
  var t = new e.constructor(e.source, Rl.exec(e));
  return t.lastIndex = e.lastIndex, t;
}
var Ul = Vl, ot = Ee, lt = ot ? ot.prototype : void 0, ut = lt ? lt.valueOf : void 0;
function Bl(e) {
  return ut ? Object(ut.call(e)) : {};
}
var Gl = Bl, Wl = We;
function Kl(e, t) {
  var a = t ? Wl(e.buffer) : e.buffer;
  return new e.constructor(a, e.byteOffset, e.length);
}
var zl = Kl, Hl = We, Yl = Nl, Jl = Ul, ql = Gl, xl = zl, Ql = "[object Boolean]", Xl = "[object Date]", Zl = "[object Map]", eu = "[object Number]", tu = "[object RegExp]", au = "[object Set]", su = "[object String]", ru = "[object Symbol]", nu = "[object ArrayBuffer]", iu = "[object DataView]", ou = "[object Float32Array]", lu = "[object Float64Array]", uu = "[object Int8Array]", du = "[object Int16Array]", cu = "[object Int32Array]", pu = "[object Uint8Array]", fu = "[object Uint8ClampedArray]", hu = "[object Uint16Array]", vu = "[object Uint32Array]";
function gu(e, t, a) {
  var s = e.constructor;
  switch (t) {
    case nu:
      return Hl(e);
    case Ql:
    case Xl:
      return new s(+e);
    case iu:
      return Yl(e, a);
    case ou:
    case lu:
    case uu:
    case du:
    case cu:
    case pu:
    case fu:
    case hu:
    case vu:
      return xl(e, a);
    case Zl:
      return new s();
    case eu:
    case su:
      return new s(e);
    case tu:
      return Jl(e);
    case au:
      return new s();
    case ru:
      return ql(e);
  }
}
var mu = gu, yu = le, dt = Object.create, bu = function() {
  function e() {
  }
  return function(t) {
    if (!yu(t))
      return {};
    if (dt)
      return dt(t);
    e.prototype = t;
    var a = new e();
    return e.prototype = void 0, a;
  };
}(), $u = bu, _u = $u, wu = Et, Tu = Re;
function ku(e) {
  return typeof e.constructor == "function" && !Tu(e) ? _u(wu(e)) : {};
}
var Cu = ku, Su = Ge, Au = ue, Iu = "[object Map]";
function Pu(e) {
  return Au(e) && Su(e) == Iu;
}
var Ou = Pu, ju = Ou, Du = Fe, ct = Ne, pt = ct && ct.isMap, Eu = pt ? Du(pt) : ju, Mu = Eu, Lu = Ge, Fu = ue, Nu = "[object Set]";
function Ru(e) {
  return Fu(e) && Lu(e) == Nu;
}
var Vu = Ru, Uu = Vu, Bu = Fe, ft = Ne, ht = ft && ft.isSet, Gu = ht ? Bu(ht) : Uu, Wu = Gu, Ku = rn, zu = on, Hu = kt, Yu = Zi, Ju = mo, qu = yo, xu = $o, Qu = Do, Xu = zo, Zu = Zo, ed = rl, td = Ge, ad = Ol, sd = mu, rd = Cu, nd = Le, id = St, od = Mu, ld = le, ud = Wu, dd = Ve, cd = Ue, pd = 1, fd = 2, hd = 4, Nt = "[object Arguments]", vd = "[object Array]", gd = "[object Boolean]", md = "[object Date]", yd = "[object Error]", Rt = "[object Function]", bd = "[object GeneratorFunction]", $d = "[object Map]", _d = "[object Number]", Vt = "[object Object]", wd = "[object RegExp]", Td = "[object Set]", kd = "[object String]", Cd = "[object Symbol]", Sd = "[object WeakMap]", Ad = "[object ArrayBuffer]", Id = "[object DataView]", Pd = "[object Float32Array]", Od = "[object Float64Array]", jd = "[object Int8Array]", Dd = "[object Int16Array]", Ed = "[object Int32Array]", Md = "[object Uint8Array]", Ld = "[object Uint8ClampedArray]", Fd = "[object Uint16Array]", Nd = "[object Uint32Array]", A = {};
A[Nt] = A[vd] = A[Ad] = A[Id] = A[gd] = A[md] = A[Pd] = A[Od] = A[jd] = A[Dd] = A[Ed] = A[$d] = A[_d] = A[Vt] = A[wd] = A[Td] = A[kd] = A[Cd] = A[Md] = A[Ld] = A[Fd] = A[Nd] = !0;
A[yd] = A[Rt] = A[Sd] = !1;
function pe(e, t, a, s, i, n) {
  var r, o = t & pd, l = t & fd, u = t & hd;
  if (a && (r = i ? a(e, s, i, n) : a(e)), r !== void 0)
    return r;
  if (!ld(e))
    return e;
  var h = nd(e);
  if (h) {
    if (r = ad(e), !o)
      return xu(e, r);
  } else {
    var m = td(e), S = m == Rt || m == bd;
    if (id(e))
      return qu(e, o);
    if (m == Vt || m == Nt || S && !i) {
      if (r = l || S ? {} : rd(e), !o)
        return l ? Xu(e, Ju(r, e)) : Qu(e, Yu(r, e));
    } else {
      if (!A[m])
        return i ? e : {};
      r = sd(e, m, o);
    }
  }
  n || (n = new Ku());
  var w = n.get(e);
  if (w)
    return w;
  n.set(e, r), ud(e) ? e.forEach(function(j) {
    r.add(pe(j, t, a, j, e, n));
  }) : od(e) && e.forEach(function(j, E) {
    r.set(E, pe(j, t, a, E, e, n));
  });
  var D = u ? l ? ed : Zu : l ? cd : dd, T = h ? void 0 : D(e);
  return zu(T || e, function(j, E) {
    T && (E = j, j = e[E]), Hu(r, E, pe(j, t, a, E, e, n));
  }), r;
}
var Rd = pe, Vd = Rd, Ud = 1, Bd = 4;
function Gd(e) {
  return Vd(e, Ud | Bd);
}
var Wd = Gd;
const Kd = /* @__PURE__ */ ca(Wd);
function zd(e) {
  if (typeof e != "function")
    throw console.log("validateFactory", e), new Error("QueryFactory must be a function");
  if (e.params && typeof e != "object")
    throw console.log("validateFactory", e), new Error("If QueryFactory has a property or getter 'params', it must be an object");
}
function gf(e) {
  zd(e);
  let t;
  const a = g(null), s = g(Kd(e.params));
  function i(o) {
    t = e(o);
  }
  function n(o) {
    return {
      data: o.data(),
      id: o.id,
      path: o.path,
      ref() {
        return o.ref;
      }
    };
  }
  function r(o) {
    if ((o !== void 0 || !t) && i(o), !t.valid)
      throw console.log("queryFactoryResult", t), new Error("queryFactoryResult is invalid, cannot run query");
    t.ref.get().then((l) => {
      if (l.empty !== void 0)
        a.value = l.docs.map((h) => n(h));
      else {
        var u = l;
        a.value = n(u);
      }
    }).catch((l) => {
      console.log(e), console.warn("An error occurred while running a query"), console.error(l);
    });
  }
  return H(() => {
    r();
  }), Yt(() => {
    r();
  }), { runQuery: r, queryResult: a, queryParams: s };
}
function mf(e) {
  const t = g([]);
  return Jt(() => {
    const a = e.items.map((s) => "data" in s && s.data ? s : {
      data: typeof s == "object" ? s : { id: s },
      get id() {
        return this.data.id || this.data.$id || this.data.name;
      },
      get name() {
        return this.data.name || this.data.id || this.data.$id;
      },
      active: !1
    });
    for (; t.value.length > 0; )
      t.value.pop();
    for (const s of a)
      t.value.push(s);
  }), t;
}
var vt;
((e) => {
  const a = `.feature-flag-disabled {
        filter: grayscale(1);
    }
    .feature-flag-disabled:after {
        width: 25px;
        height: 25px;
        display:block;
        position:absolute;
        content:'';
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAADR0lEQVRoge1ZPU8UURQ9d2a/kCUadBNUCoRVCqJAEALdGrRA+TKw0upfsLKkMrb+ABMTC2PQ0thSGEMjwYLEiCZEGwyGgJJAWOYdCwwQXXbe1yIFJ9lq3v04e8+8d98d4BjHqArElyN2jBcJeWQWnA9k7uWUj/gJH04AgCITAJqNbCB3AHgh4qUizA+kmc0uQ1BnaLouJ2tzMv100zWHwNUBACBb129BAgCyWFu/5iMFL0SUYNjaFva2++FMhIAIeMvWXiDD9CBx94p0TFwF0Ojg4Rw6x7pc03AmooTO0lAQZx/ORAQccfchzj6czhG+6W3Cx6UUfoURFUIbHxIgQl2U5sPeJhmYWbTNxe1AzK+NMo9WFxcEQgCtQowAeGzrx0laBJwlsetL3ORlve1xvq2eKfUd/tqcSFTUIK2fftgY21ckrQbhsVcDECIIB2yNrYn4lNWeT3t5Wf2jLBQy/Lbagq1gGRthPSx3rF0EiFATrSCl8iwUMjI9bdxE2knj55nruJ9rt7ItjxBADkAORD+A16YOrKTlq9Hz6duYCAERyk2bYDoQwZBNE2leka6xHgDnje30cRbtxW5TI2Miiu4NXmwMi/uNMRGhe4MXGyMw39qNtMjusWZuB19Mg9hABBdlduqz7nqzikRh1auxBw6arDYiQrpforRjGV62tKXF+bZ6gh+wHdRgK8iwJBmh44n+dwxBJEluIqU2kVAbAmmXtvkVHVv9kz2tBkE0IhUBJyJ/I8p9kJ0TvvbPD9gZajzTsdWWVjWaxPiY+juk1h/LhXyaSC4DVkM4F6xLYiMnFxZjm0i9ijBxA4dPAgCyKNX06yzUIqIcr6EuUKIn6diXnZgM+OTtZQRqFSU55Z6aAZJcFRVcISYCwaSqtDT2HWFnsY/AO3/ZmUMk6JPZFzOV1sRKq5p3D10ojYM4loj8h2333xzip5kVpcWuiRYqpd24VRNCdUnmXi0c9LxyRVQ06j0jW0gwVOlxRSKmjVs1wZh39UBpsef2aZYSS/A7hHNBJEGiQd4/LzuJPLgiW0nfk0RXhFClA4ceCXaMF8o9INRdj5/hvYDEPXaMfy33TFRnkYedUDXg5/P0EcAxkaOGYyJHDb8Bn47flXuY46sAAAAASUVORK5CYII=');
        background-size: 65%;
        background-position:50% 50%;
        background-repeat: no-repeat;
        border:orange solid 2px;
        border-radius:100%;           
    }`, s = document.createElement("style");
  s.type = "text/css", s.appendChild(document.createTextNode(a));
  let i;
  ((l) => {
    l.show = "show", l.enable = "enable";
  })(i || (i = {}));
  const n = {
    flags: null,
    environment: void 0
  };
  function r() {
    console.log("Feature Flags:", n.flags);
  }
  function o(l) {
    if (n.flags === null)
      console.warn(`FeatureFlags has no hash configured. Cannot check if feature "${l}" is enabled.`);
    else {
      let u = n.flags[l];
      return u === void 0 && console.warn(`No Feature Flag named "${l}" was found. Assuming disabled.`), !!u || !1;
    }
  }
  e.isFeatureEnabled = o, e.install = (l, u) => {
    var h;
    (h = document.querySelector("body")) == null || h.append(s), (!u || !u.flags) && console.warn("FeatureFlags has no hash configured. A feature hash is required to use FeatureFlags."), n.environment = u.env, n.flags = u.flags, n.environment && n.environment !== "prod" && n.environment !== "production" && r(), l.directive("if-feature", {
      mounted: (m, S) => {
        console.log("if-feature is mounted");
        const w = S.value, D = S.arg ? S.arg : "show", T = m.style.display || "";
        D === "enable" ? o(w) ? (m.removeAttribute("disabled"), m.classList.remove("feature-flag-disabled")) : (m.setAttribute("disabled", "true"), m.classList.add("feature-flag-disabled")) : D === "show" && (o(w) ? m.style.display = T : m.style.display = "none");
      }
    });
  }, e.plugin = {
    install: e.install
  };
})(vt || (vt = {}));
function Hd(e, t) {
  const a = PLATFORM || "web";
  if (!e || e === "only")
    return t.includes(a);
  if (e === "exclude")
    return !t.includes(a);
}
const yf = {
  install: (e) => {
    e.directive("if-platform", {
      mounted(t, a) {
        console.log("if-platform");
        const s = a.value instanceof Array ? a.value : [a.value], i = a.arg ? a.arg : "only", n = t.style.display;
        Hd(i, s) ? t.style.display = n : t.style.display = "none";
      }
    });
  }
}, bf = {
  install(e, { router: t, conditions: a }) {
    var s = a;
    function i(r, o) {
      if (o.exclude && o.exclude.length > 0) {
        var l = !1;
        for (let h = 0; h < o.exclude.length; h++) {
          var u = o.exclude[h];
          if (r.path.indexOf(u) >= 0) {
            l = !0;
            break;
          }
        }
        if (l)
          return !1;
      }
      return !0;
    }
    async function n(r, o) {
      let l = !0, u = !0;
      return await Promise.all(s.map(async (h) => {
        if (l)
          if (i(r, h)) {
            const m = await h.allowNextIf(r, o);
            return m !== !0 ? (l = !1, u = m === !1 ? h.redirect : m, r.path.indexOf(u) >= 0 ? (u = !0, !1) : u) : !0;
          } else
            return !0;
        else
          return !1;
      })), l ? !0 : u;
    }
    t.beforeEach(async (r, o, l) => {
      if (r === o)
        l();
      else {
        const u = await n(r, o);
        u === !0 ? r.meta && "allowNextIf" in r.meta && typeof r.meta.allowNextIf == "function" ? r.meta.allowNextIf(r, o, l) && l() : l() : u && l(u);
      }
    });
  }
}, Ut = {
  AK: "Alaska",
  AL: "Alabama",
  AR: "Arkansas",
  AZ: "Arizona",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District of Columbia",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  IA: "Iowa",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  MA: "Massachusetts",
  MD: "Maryland",
  ME: "Maine",
  MI: "Michigan",
  MN: "Minnesota",
  MO: "Missouri",
  MS: "Mississippi",
  MT: "Montana",
  NC: "North Carolina",
  ND: "North Dakota",
  NE: "Nebraska",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NV: "Nevada",
  NY: "New York",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VA: "Virginia",
  VT: "Vermont",
  WA: "Washington",
  WI: "Wisconsin",
  WV: "West Virginia",
  WY: "Wyoming"
}, Yd = {
  AS: "American Samoa",
  GU: "Guam",
  MP: "Northern Mariana Islands",
  PR: "Puerto Rico",
  VI: "U.S. Virgin Islands"
}, Jd = Object.assign({}, Ut, Yd), $f = Object.keys(Ut), _f = Object.keys(Jd), ee = {
  emitter: null,
  initialized: !1
};
function Bt() {
  if (!ee.initialized)
    throw new Error("You must call initEvents one time before calling this function");
}
function qd(e) {
  Bt(), ee.emitter = e;
}
function wf(e) {
  if (ee.initialized)
    throw new Error("initEvents should not be called more than once in application");
  ee.initialized = !0, e ? qd(e) : ee.emitter = Y();
}
function xd() {
  return Bt(), ee.emitter;
}
const ce = {};
function Qd(e) {
  return ce[e] || (ce[e] = 0), ce[e] += 1, `${e}${ce[e]}`;
}
const Tf = {
  install(e, { router: t, redirect: a }) {
    if (!t)
      throw new Error("router must be provided in options for MissingRoutes plugin in Vue.use(...)");
    if (!a)
      throw new Error("redirect must be defined in options for MissingRoutes plugin in Vue.use(...)");
    t.beforeEach((s, i, n) => {
      s.matched.length === 0 ? typeof a == "string" ? n(a) : a(s, i, n) : n();
    });
  }
};
class kf {
  constructor(t, { pageSize: a = 50 } = {}) {
    k(this, "items");
    k(this, "pageSize");
    k(this, "activePageNumber");
    k(this, "lastPage");
    k(this, "pageFirstItemIndex");
    k(this, "pageLastItemIndex");
    k(this, "emitter");
    k(this, "totalPages", 0);
    k(this, "activePageItems", []);
    this.items = t, this.pageSize = a, this.activePageNumber = 1, this.lastPage = 1, this.pageFirstItemIndex = -1, this.pageLastItemIndex = -1, this.emitter = Y(), this.calculate();
  }
  get empty() {
    return this.items.length === 0;
  }
  submitPageChange() {
    const t = {
      activePageItems: this.activePageItems,
      activePageNumber: this.activePageNumber,
      totalPages: this.totalPages
    };
    this.emitter.emit("page:change", t);
  }
  changeActivePage(t) {
    this.activePageNumber = t, this.calculate(), this.submitPageChange();
  }
  updateItems(t) {
    this.items = t, this.calculate(), this.submitPageChange();
  }
  updatePageSize(t) {
    this.pageSize = t, this.calculate(), this.submitPageChange();
  }
  calculate() {
    this.totalPages = Math.ceil(this.items.length / this.pageSize), this.activePageNumber = this.clampPageIndex(this.activePageNumber), this.pageFirstItemIndex = (this.activePageNumber - 1) * this.pageSize, this.pageLastItemIndex = this.activePageNumber * this.pageSize, this.lastPage = this.totalPages, this.activePageItems = this.items.slice(this.pageFirstItemIndex, this.pageLastItemIndex);
  }
  relativePageChange(t) {
    var a = this.activePageNumber + t;
    this.goToPage(a);
  }
  pageIndexValid(t) {
    return t <= this.totalPages && t >= 1;
  }
  clampPageIndex(t) {
    return t < 1 ? 1 : t > this.totalPages ? this.totalPages : t;
  }
  goToPage(t) {
    this.pageIndexValid(t) && (this.changeActivePage(t), this.submitPageChange());
  }
  nextPage() {
    this.relativePageChange(1);
  }
  previousPage() {
    this.relativePageChange(-1);
  }
  onPageChange(t) {
    this.emitter.on("page:change", t);
  }
  offPageChange(t) {
    this.emitter.off("page:change", t);
  }
}
const Xd = 5;
class Cf {
  /**
   * Constructs a new ProgressTracker object.
   * @param {ProgressTrackerOptions} options
   */
  constructor({ steps: t, mode: a, seconds: s = 1 / 0, autoDestroy: i = !0 }) {
    k(this, "autoDestroy");
    k(this, "stepsCompleted");
    k(this, "timerInterval");
    k(this, "timerTimeout");
    k(this, "progress");
    k(this, "done");
    k(this, "seconds");
    k(this, "running");
    k(this, "isTimed");
    k(this, "mode");
    k(this, "steps");
    k(this, "totalSteps");
    k(this, "emitter");
    this.autoDestroy = i, this.stepsCompleted = 0, this.timerInterval = null, this.timerTimeout = null, this.progress = 0, this.done = !1, this.seconds = s, this.running = !1, this.isTimed = this.seconds !== 1 / 0 && this.seconds > 0, this.emitter = Y(), t === 1 / 0 || a === "infinite" ? (this.mode = "infinite", this.steps = null, this.totalSteps = 1 / 0) : typeof t == "number" ? (this.mode = "finite", this.steps = null, this.totalSteps = this.steps) : t instanceof Array ? (this.mode = "finite", this.steps = t.map((n) => ({ completed: !1, value: n })), this.totalSteps = t.length) : (this.isTimed || (this.seconds = Xd), this.mode = "approximate", this.totalSteps = this.seconds, this.steps = null, this.mode = "approximate");
  }
  get currentStep() {
    return this.totalSteps === 1 / 0 ? null : this.steps && this.steps.length > 0 ? this.steps[this.stepsCompleted] : this.stepsCompleted + 1;
  }
  start() {
    var t;
    this.running = !0, (t = this.emitter) == null || t.emit("start"), this.isTimed && this.startTimer();
  }
  startTimer() {
    if (!this.seconds)
      throw new Error("ProgressTracker cannot start a timer if seconds is unset");
    this.totalSteps !== 1 / 0 && (this.timerInterval = setInterval(() => {
      this.incrementProgress(1);
    }, 1e3)), this.timerTimeout = setTimeout(() => {
      this.timerInterval && clearInterval(this.timerInterval), this.setDone();
    }, 1e3 * this.seconds);
  }
  destroy() {
    this.emitter && this.emitter.off("*"), delete this.emitter, this.totalSteps = null;
  }
  onStart(t) {
    this.emitter || (this.emitter = Y()), this.emitter.on("start", t);
  }
  onProgress(t) {
    this.emitter || (this.emitter = Y()), this.emitter.on("progress", t);
  }
  onDone(t) {
    this.emitter || (this.emitter = Y()), this.emitter.on("done", t);
  }
  recalculate() {
    this.mode === "finite" && (this.progress = this.stepsCompleted / (this.totalSteps ?? 0)), this.done || this.stepsCompleted >= (this.totalSteps ?? 0) ? this.emitDone() : this.emitter && this.emitter.emit("progress", { progress: this.progress, seconds: this.seconds });
  }
  incrementProgress(t = 1) {
    this.stepsCompleted += t, this.recalculate();
  }
  updateProgress(t) {
    this.stepsCompleted = t, this.recalculate();
  }
  completeStep(t) {
    var s;
    if (this.mode !== "finite")
      throw new Error("Cannot call completeStep unless the ProgressTracker.mode is finite");
    var a = (s = this.steps) == null ? void 0 : s.find((i) => i.value == t);
    if (!a)
      throw new Error(`ProgressTracker step not found: "${t}"`);
    a.completed || (a.completed = !0, this.incrementProgress(1));
  }
  emitDone() {
    this.done = !0, this.running = !1, this.emitter && this.emitter.emit("done"), this.timerTimeout && clearTimeout(this.timerTimeout), this.timerInterval && clearInterval(this.timerInterval), this.autoDestroy && this.destroy();
  }
  setDone() {
    this.done = !0, this.mode === "finite" && (this.stepsCompleted = this.totalSteps), this.recalculate();
  }
}
const Oe = {
  loopTime: 1e3,
  stack: [],
  repeatCount: 0,
  timerTimeout: null,
  stopImmediately: !1,
  // Letting the loop run a few times before stopping it can help 
  timeLimitReached: !1,
  stackLimitReached: !1,
  cleanupTimeout: null,
  delayedCleanup() {
    this.cleanupTimeout || (this.cleanupTimeout = setTimeout(() => {
      this.reset(), this.cleanupTimeout = null;
    }, 100));
  },
  startTimer() {
    this.timeLimitReached = !1, this.repeatCount = 0, this.timerTimeout = setTimeout(() => {
      this.timeLimitReached = !0, this.checkStack(), this.timerTimeout = null, this.delayedCleanup();
    }, Oe.loopTime);
  },
  checkStack() {
    this.repeatCount = 0;
    for (let a = 0; a < this.stack.length; a++) {
      var e = this.stack[a], t = this.countRoutesInStack(e);
      t > 1 && this.repeatCount++;
    }
    return this.repeatCount;
  },
  countRoutesInStack(e) {
    var t = 0;
    return this.stack.forEach((a) => {
      e.path === a.path && t++;
    }), t;
  },
  reset() {
    this.stackLimitReached = !1, this.timeLimitReached = !1, this.stack = [];
  },
  add(e) {
    !this.timerTimeout && !this.timeLimitReached && this.startTimer(), this.stack.length < 10 ? (this.stack.push(e), this.checkStack()) : this.stackLimitReached = !0;
  },
  hook(e, t, a) {
    this.add(e), this.stackLimitReached || this.repeatCount > 0 && (this.stopImmediately || this.timeLimitReached) ? (console.error("Redirect loop blocked: ", this.stack.map((s) => s.path)), this.reset()) : ((this.timeLimitReached || !this.timerTimeout) && (this.reset(), this.startTimer()), a());
  }
}, Sf = {
  install(e, { router: t, options: a = {} }) {
    Oe.stopImmediately = !!a.stopImmediately, t.beforeEach((s, i, n) => {
      Oe.hook(s, i, n);
    });
  }
}, Gt = 50, Zd = 10, ec = Zd * 1e3 / Gt;
function Af(e) {
  const t = g(null);
  return e && H(() => {
    if (typeof e == "string")
      var a = 0, s = setInterval(() => {
        a++, document.querySelector(e) && (clearInterval(s), t.value = e), a >= ec && clearInterval(s);
      }, Gt);
  }), t;
}
const If = {
  install: function(e, { FireObject: t } = {}) {
    if (!t)
      throw new Error("FireObject must be passed in on VueFireObjectsPlugin.install");
    e.mixin({
      created: function() {
        if (this.$options.fireObjects)
          for (let a in this.$options.fireObjects)
            this.$data[a] = this.$options.fireObjects[a];
      }
    });
  }
};
class Wt {
  constructor() {
    k(this, "tasks");
    k(this, "events");
    k(this, "_progressRef");
    k(this, "working");
    this.tasks = [], this.events = Y(), this._progressRef = g(0), this.working = g(!1);
  }
  addTask(t, a) {
    const s = { progress: 0 };
    if (typeof t == "string" && typeof a > "u")
      s.name = t;
    else if (typeof t == "object" && "then" in t)
      s.promise = t;
    else if (typeof a < "u" && typeof t == "string")
      s.name = t, s.promise = a;
    else if (t instanceof Wt)
      s.watcher = t;
    else
      throw new TypeError("First argument not supported");
    if (typeof s.promise < "u" && s.promise.then(() => {
      s.progress = 1, this.updateProgress();
    }).catch((n) => {
      s.progress = 0, this.events.emit("done", n);
    }), typeof s.watcher < "u" && s.watcher.onDone((n) => {
      typeof n < "u" ? (s.progress = 0, this.events.emit("done", n)) : (s.progress = 1, this.updateProgress());
    }), typeof s.name < "u") {
      const n = this.tasks.findIndex((r) => r.name === s.name);
      n !== -1 && this.tasks.splice(n, 1);
    }
    const i = this.working.value;
    this.working.value = !0, this.tasks.push(s), i || this.events.emit("working", !0);
  }
  /**
   * Finishes a named task. Promised tasks cannot be finished this way
   * @param name 
   */
  finishTask(t) {
    this.updateTask(t, 1);
  }
  /**
   * Update the progress on a task
   * @param name 
   * @param progress 
   */
  updateTask(t, a) {
    const s = this.tasks.find((i) => i.name === t);
    if (typeof s > "u")
      throw new Error(`No task found with the name ${t}`);
    if (typeof s.promise < "u")
      throw new Error("Promise tasks cannot be set by name");
    s.progress = a, this.updateProgress();
  }
  updateProgress() {
    const t = this.tasks.length, s = this.tasks.reduce((i, n) => i + Math.min(n.progress, 1), 0) / t;
    this.events.emit("progress", s), this._progressRef.value = s, s >= 1 && (this.events.emit("done"), this.working.value = !1, this.events.emit("working", !1));
  }
  /**
   * Fire an event when this watcher's tasks are all completed
   * This can fire multiple times if multiple tasks are added after completion
   * @param handler Error will be undefined if successful. 
   */
  onDone(t) {
    this.events.on("done", t);
  }
  /**
   * Fire an event whenever progress is made
   * @param handler Progress is a number between 0 and 1
   */
  onProgress(t) {
    this.events.on("progress", t);
  }
  /**
   * Fire an event when working status has changed
   * @param handler 
   */
  onWorkingChange(t) {
    this.events.on("working", t);
  }
  /**
   * 
   * @returns A vue computedref for the current progress, between 0-1
   */
  progressRef() {
    return G(() => this._progressRef.value);
  }
  /**
   * 
   * @returns A vue computedref boolean value for the current working status
   */
  workingRef() {
    return G(() => this.working.value);
  }
}
const tc = { class: "login-component" }, ac = /* @__PURE__ */ d("h2", null, "Login", -1), sc = ["disabled"];
function rc(e, t, a, s, i, n) {
  return c(), p("form", tc, [
    ac,
    d("fieldset", {
      class: N({ invalid: e.validation.username === !1, valid: e.validation.username === !0 })
    }, [
      V(d("input", {
        type: "email",
        "onUpdate:modelValue": t[0] || (t[0] = (r) => e.username = r),
        placeholder: "Username",
        onBlur: t[1] || (t[1] = (...r) => e.checkValidInput && e.checkValidInput(...r)),
        onKeyup: t[2] || (t[2] = (r) => e.validateWithDelay(e.validateUsername))
      }, null, 544), [
        [W, e.username]
      ])
    ], 2),
    d("fieldset", {
      class: N({ invalid: e.validation.password === !1, valid: e.validation.password === !0 })
    }, [
      V(d("input", {
        type: "password",
        "onUpdate:modelValue": t[3] || (t[3] = (r) => e.password = r),
        placeholder: "Password",
        onBlur: t[4] || (t[4] = (...r) => e.checkValidInput && e.checkValidInput(...r)),
        onKeyup: t[5] || (t[5] = (r) => e.validateWithDelay(e.validatePassword))
      }, null, 544), [
        [W, e.password]
      ])
    ], 2),
    V(d("div", { class: "error-message" }, C(e.error), 513), [
      [qt, e.showError]
    ]),
    d("button", {
      class: "primary",
      type: "button",
      onClick: t[6] || (t[6] = (...r) => e.onTapLogin && e.onTapLogin(...r)),
      disabled: !e.formValid || e.loggingIn
    }, C(e.loginButtonLabel), 9, sc)
  ]);
}
const U = (e, t) => {
  const a = e.__vccOpts || e;
  for (const [s, i] of t)
    a[s] = i;
  return a;
}, nc = L({
  props: {
    getAuth: {
      type: Function
    }
  },
  setup(e, { emit: t }) {
    return Object.assign(
      {},
      De(e.getAuth(), { mode: "login", emit: t, minPasswordScore: e.minPasswordScore })
    );
  }
}), ic = /* @__PURE__ */ U(nc, [["render", rc]]), oc = { class: "signup-component-container" }, lc = /* @__PURE__ */ d("h2", null, "Signup", -1), uc = { key: 0 }, dc = {
  key: 0,
  class: "mb-40"
}, cc = { key: 0 }, pc = ["disabled"];
function fc(e, t, a, s, i, n) {
  return c(), p("form", oc, [
    lc,
    d("div", {
      class: N(["fieldset", { invalid: e.validation.username === !1, valid: e.validation.username === !0 }])
    }, [
      V(d("input", {
        type: "email",
        "onUpdate:modelValue": t[0] || (t[0] = (r) => e.username = r),
        placeholder: "Username",
        onBlur: t[1] || (t[1] = (...r) => e.validateUsername && e.validateUsername(...r)),
        onKeyup: t[2] || (t[2] = (r) => e.validateWithDelay(e.validateUsername))
      }, null, 544), [
        [W, e.username]
      ]),
      e.validation.username ? (c(), p("i", uc, "✓")) : y("", !0)
    ], 2),
    d("div", {
      class: N(["fieldset", { invalid: e.validation.password === !1, valid: e.validation.password === !0 }])
    }, [
      V(d("input", {
        type: "password",
        "onUpdate:modelValue": t[3] || (t[3] = (r) => e.password = r),
        placeholder: "Password",
        onKeyup: t[4] || (t[4] = (r) => e.validatePassword(e.password))
      }, null, 544), [
        [W, e.password]
      ]),
      e.validation.password ? (c(), p("i", dc, "✓")) : y("", !0)
    ], 2),
    d("div", {
      class: N(["fieldset", { invalid: e.validation.confirmPassword === !1, valid: e.validation.confirmPassword === !0 }])
    }, [
      V(d("input", {
        type: "password",
        "onUpdate:modelValue": t[5] || (t[5] = (r) => e.confirmPassword = r),
        placeholder: "Confirm Password",
        onBlur: t[6] || (t[6] = (...r) => e.validateConfirmPassword && e.validateConfirmPassword(...r)),
        onKeyup: t[7] || (t[7] = (r) => e.validateWithDelay(e.validateConfirmPassword))
      }, null, 544), [
        [W, e.confirmPassword]
      ]),
      e.validation.confirmPassword ? (c(), p("i", cc, "✓")) : y("", !0)
    ], 2),
    d("button", {
      class: "primary",
      type: "button",
      onClick: t[8] || (t[8] = (...r) => e.onTapCreate && e.onTapCreate(...r)),
      disabled: !e.formValid
    }, "Create Account", 8, pc)
  ]);
}
const hc = L({
  props: {
    getAuth: {
      type: Function
    },
    minPasswordScore: {
      type: Number,
      default: 3
    }
  },
  methods: {
    goBack() {
      this.$emit("back");
    }
  },
  setup(e, { emit: t }) {
    return Object.assign(
      {},
      De(e.getAuth(), { mode: "signup", emit: t, minPasswordScore: e.minPasswordScore })
    );
  }
}), vc = /* @__PURE__ */ U(hc, [["render", fc]]), gc = { class: "reset-credentials-component" }, mc = /* @__PURE__ */ d("h2", null, "Reset Credentials", -1), yc = ["disabled"], bc = /* @__PURE__ */ L({
  __name: "reset-credentials",
  props: {
    auth: {},
    minPasswordScore: {}
  },
  emits: ["reset", "error"],
  setup(e, { emit: t }) {
    const a = e, { username: s, validateUsername: i, validateWithDelay: n, validation: r, processing: o, onTapReset: l } = De(a.auth, { emit: t, mode: "reset", minPasswordScore: a.minPasswordScore });
    return (u, h) => (c(), p("form", gc, [
      mc,
      V(d("input", {
        class: "email-input",
        type: "email",
        "onUpdate:modelValue": h[0] || (h[0] = (m) => xt(s) ? s.value = m : null),
        placeholder: "Email",
        onBlur: h[1] || (h[1] = //@ts-ignore
        (...m) => M(i) && M(i)(...m)),
        onKeyup: h[2] || (h[2] = (m) => M(n)(M(i)))
      }, null, 544), [
        [W, M(s)]
      ]),
      d("button", {
        class: "reset-button primary",
        type: "button",
        disabled: !M(r).username || M(o),
        onClick: h[3] || (h[3] = //@ts-ignore
        (...m) => M(l) && M(l)(...m))
      }, "Send Reset Email ", 8, yc)
    ]));
  }
});
const $c = { class: "auth-component" }, _c = /* @__PURE__ */ d("h2", null, "Logged In", -1), wc = { class: "auth-menu" };
var Kt = /* @__PURE__ */ ((e) => (e[e.login = 1] = "login", e[e.signup = 2] = "signup", e[e.reset = 3] = "reset", e))(Kt || {});
const Pf = /* @__PURE__ */ L({
  __name: "auth",
  props: {
    minPasswordScore: { default: 3 },
    features: { default: () => ["login", "signup", "reset"] },
    auth: {}
  },
  emits: ["signout", "signup", "login", "reset", "error"],
  setup(e, { emit: t }) {
    const a = e, s = g(
      1
      /* login */
    ), i = G(() => a.features.includes("login")), n = G(() => a.features.includes("signup")), r = G(() => a.features.includes("reset")), o = g(a.auth.currentUser), l = g(null);
    function u() {
      !n.value && s.value === 2 && (s.value = 1), !i.value && s.value === 1 && (s.value = 3), !r.value && s.value === 3 && (s.value = 2);
    }
    H(() => {
      l.value = ua(a.auth, (T) => {
        T && (o.value = T);
      }), u();
    }), ge(() => {
      l.value && l.value();
    });
    async function h() {
      await a.auth.signOut(), t("signout");
    }
    const m = () => t("signup"), S = () => t("login"), w = () => t("reset"), D = (T) => t("error", T);
    return (T, j) => (c(), p("div", $c, [
      o.value ? B(T.$slots, "loggedin", { key: 0 }, () => [
        _c,
        d("p", null, "Logged in as " + C(o.value.email), 1),
        d("button", { onClick: h }, "Log Out")
      ]) : y("", !0),
      !o.value && s.value === 1 && i.value ? B(T.$slots, "login", { key: 1 }, () => [
        oe(ic, {
          onLogin: S,
          onError: D
        })
      ]) : y("", !0),
      !o.value && s.value === 2 && n.value ? B(T.$slots, "signup", {
        key: 2,
        minPasswordScore: T.minPasswordScore
      }, () => [
        oe(vc, {
          "min-password-score": T.minPasswordScore,
          onSignup: m
        }, null, 8, ["min-password-score"])
      ]) : y("", !0),
      !o.value && s.value === 3 && r.value ? B(T.$slots, "reset", { key: 3 }, () => [
        oe(bc, {
          onReset: w,
          auth: T.auth,
          "min-password-score": T.minPasswordScore
        }, null, 8, ["auth", "min-password-score"])
      ]) : y("", !0),
      o.value ? y("", !0) : B(T.$slots, "menu", {
        key: 4,
        mode: s.value,
        modes: Kt
      }, () => [
        d("nav", wc, [
          d("li", null, [
            s.value !== 1 && i.value ? (c(), p("button", {
              key: 0,
              class: "auth-login",
              onClick: j[0] || (j[0] = (E) => s.value = 1)
            }, "Log In")) : y("", !0)
          ]),
          d("li", null, [
            s.value !== 2 && n.value ? (c(), p("button", {
              key: 0,
              class: "auth-signup",
              onClick: j[1] || (j[1] = (E) => s.value = 2)
            }, "Sign Up")) : y("", !0)
          ]),
          d("li", null, [
            s.value !== 3 && r.value ? (c(), p("button", {
              key: 0,
              class: "auth-reset",
              onClick: j[2] || (j[2] = (E) => s.value = 3)
            }, "Reset Password")) : y("", !0)
          ])
        ])
      ])
    ]));
  }
});
const Tc = {
  key: 0,
  class: "prefix"
}, kc = ["value"], Cc = {
  disabled: "",
  value: ""
}, Sc = ["value"], Ac = ["type", "value", "placeholder", "maxLength"], Ic = {
  key: 2,
  class: "value"
}, Pc = { key: 0 }, Oc = { key: 1 };
function jc(e, t, a, s, i, n) {
  return c(), p("div", {
    class: N(["fire-input", e.resolvedClasses])
  }, [
    e.prefix ? (c(), p("sub", Tc, C(e.prefix), 1)) : y("", !0),
    e.editing ? (c(), p(J, { key: 1 }, [
      e.type === "select" ? (c(), p("select", {
        key: 0,
        value: e.workingValue,
        onInput: t[0] || (t[0] = (...r) => e.onInput && e.onInput(...r))
      }, [
        d("option", Cc, C(e.placeholder || "Select one"), 1),
        (c(!0), p(J, null, Q(e.resolvedOptions, (r) => (c(), p("option", {
          value: r.value
        }, C(r.label), 9, Sc))), 256))
      ], 40, kc)) : (c(), p("input", {
        key: 1,
        type: e.type,
        value: e.workingValue,
        onInput: t[1] || (t[1] = (...r) => e.onInput && e.onInput(...r)),
        placeholder: e.placeholder,
        maxLength: e.maxLength
      }, null, 40, Ac))
    ], 64)) : y("", !0),
    e.editing ? y("", !0) : (c(), p("div", Ic, C(e.resolvedValue), 1)),
    oe(Qt, { name: "fade" }, {
      default: gt(() => [
        e.status ? (c(), p("div", {
          key: 0,
          class: N(["status", e.status])
        }, [
          e.status === "save-success" ? (c(), p("span", Pc, "✓")) : y("", !0),
          e.status === "save-pending" ? (c(), p("span", Oc, "...")) : y("", !0)
        ], 2)) : y("", !0)
      ]),
      _: 1
    })
  ], 2);
}
const Dc = 1500, Ec = 2500;
function Mc(e) {
  return e[0].toUpperCase() + e.substr(1);
}
const Lc = L({
  props: {
    model: {
      type: Object
    },
    editing: {
      default: !0
    },
    field: {
      type: String
    },
    type: {
      default: "text"
    },
    options: {
      default: null
    },
    maxLength: {
      default: null
    },
    placeholder: {
      default: null
    },
    transformer: {
      default: null,
      type: Function
    },
    validation: {
      default: null,
      type: Function
    },
    prefix: {
      default: null,
      type: String
    }
  },
  data() {
    return {
      workingModel: null,
      workingValue: null,
      status: null,
      valid: !0
    };
  },
  computed: {
    resolvedOptions() {
      if (this.options) {
        var e = this.options.map((t) => typeof t == "string" || typeof t == "number" ? {
          label: Mc(`${t}`),
          value: t
        } : t);
        return e;
      } else
        return [];
    },
    resolvedValue() {
      if (this.type !== "select")
        return this.workingValue;
      var e = this.resolvedOptions.find((t) => t.value == this.workingValue);
      return e ? e.label : this.workingValue;
    },
    resolvedClasses() {
      var e = {};
      return this.prefix && (e["has-prefix"] = !0), this.valid || (e.invalid = !0), e;
    }
    // resolvedMaxLength(){
    // }
  },
  watch: {
    model: function(e, t) {
      e !== t && this.workingModel !== e && e && this.setWorkingModel(e);
    }
  },
  mounted() {
    this.setWorkingModel(this.model);
  },
  methods: {
    setWorkingModel(e) {
      this.workingModel = e;
      var t = this.workingModel.$get(this.field);
      t = this.coerceValue(t, "in"), this.workingValue = t;
    },
    onInput(e) {
      this.validation ? this.valid = this.validation(e.target.value) : this.valid = !0, this.valid && (this.workingValue = e.target.value, this.status = "save-pending", this.delayedEmitInput());
    },
    coerceValue(e, t) {
      return this.transformer ? e = this.transformer(e, t, this.workingModel) : this.type === "number" && (e = parseFloat(e)), e;
    },
    delayedEmitInput() {
      this.emitTimeout && clearTimeout(this.emitTimeout), this.emitTimeout = setTimeout(() => {
        var e = this.workingValue;
        e = this.coerceValue(e, "out"), this.workingModel.$set(this.field, e), this.$emit("input", e), this.showSaveSuccess();
      }, Dc);
    },
    showSaveSuccess() {
      this.successTimeout && clearTimeout(this.successTimeout), this.status = "save-success", this.successTimeout = setTimeout(() => {
        this.status === "save-success" && (this.status = null), this.successTimeout = null;
      }, Ec);
    }
  }
}), Of = /* @__PURE__ */ U(Lc, [["render", jc]]), Fc = { class: "repeater-item" };
function Nc(e, t, a, s, i, n) {
  return c(), Xt(Zt(e.tag), null, {
    default: gt(() => [
      B(e.$slots, "empty"),
      e.items && e.items.length > 0 ? (c(!0), p(J, { key: 0 }, Q(e.items, (r) => (c(), p("div", Fc, [
        B(e.$slots, "default", { item: r }, () => [
          d("div", null, C(r.id), 1)
        ])
      ]))), 256)) : y("", !0)
    ]),
    _: 3
  });
}
const Rc = L({
  props: {
    subset: {
      default: null
    },
    collection: {
      default: null
    },
    listen: {
      default: !1
    },
    tag: {
      default: "div"
    }
  },
  data() {
    return {
      items: [],
      workingSubset: null
    };
  },
  watch: {
    subset(e, t) {
      e && e !== t && (this.workingSubset = e);
    },
    workingSubset(e, t) {
      e && e !== t && this.onSubsetSwitch();
    },
    collection(e, t) {
      e && e !== t && this.onCollectionSwitch();
    }
  },
  mounted() {
    if (this.collection && this.subset)
      throw "Apply only collection or subset prop, not both";
    this.collection ? this.onCollectionSwitch() : (this.workingSubset = this.subset, this.onSubsetSwitch());
  },
  methods: {
    onCollectionSwitch() {
      this.workingSubset = this.collection.subset(), this.listen ? this.workingSubset.listen() : this.workingSubset.fetch();
    },
    onSubsetSwitch() {
      this.workingSubset ? this.items = this.workingSubset.items : this.items = [];
    }
  }
}), jf = /* @__PURE__ */ U(Rc, [["render", Nc]]), Vc = { key: 0 }, Uc = { key: 0 }, Bc = /* @__PURE__ */ d("div", { class: "icon" }, [
  /* @__PURE__ */ d("i", { class: "fas fa-times" })
], -1), Gc = [
  Bc
], Wc = { class: "modal-content" }, Kc = { key: 1 }, zc = ["disabled"], Hc = ["disabled"], Yc = { key: 1 };
function Jc(e, t, a, s, i, n) {
  return e.active ? (c(), p("aside", {
    key: 0,
    class: "scrim fixed",
    onClick: t[3] || (t[3] = ea((...r) => e.close && e.close(...r), ["self"]))
  }, [
    d("div", {
      class: N(["modal", e.resolvedClasses])
    }, [
      e.useHeader ? (c(), p("header", Vc, [
        e.title ? (c(), p("h3", Uc, C(e.title), 1)) : y("", !0),
        e.hideCloseButton ? y("", !0) : (c(), p("button", {
          key: 1,
          class: "close",
          onClick: t[0] || (t[0] = (...r) => e.close && e.close(...r))
        }, Gc))
      ])) : y("", !0),
      d("div", Wc, [
        B(e.$slots, "default")
      ]),
      e.useFooter ? (c(), p("footer", Kc, [
        B(e.$slots, "footer", {}, () => [
          e.secondButtonText ? (c(), p("button", {
            key: 0,
            class: "cancel",
            onClick: t[1] || (t[1] = (...r) => e.cancel && e.cancel(...r)),
            disabled: e.locked
          }, C(e.secondButtonText), 9, zc)) : y("", !0),
          d("button", {
            class: "text",
            onClick: t[2] || (t[2] = (...r) => e.confirm && e.confirm(...r)),
            disabled: e.disabledButton || e.locked
          }, C(e.buttonText ? e.buttonText : "OK"), 9, Hc),
          e.working ? (c(), p("label", Yc, "Working...")) : y("", !0)
        ])
      ])) : y("", !0)
    ], 2)
  ])) : y("", !0);
}
const qc = L({
  props: {
    title: "",
    locked: !1,
    working: !1,
    useHeader: {
      default: !0
    },
    useFooter: {
      default: !0
    },
    size: {
      default: "small"
    },
    guid: {
      default: Qd("modal")
    },
    buttonText: "",
    secondButtonText: "",
    disabledButton: !1,
    hideCloseButton: !1
  },
  data() {
    return {
      active: !1
    };
  },
  created() {
    this.emitter = xd(), this.emitter.on(`modal:${this.guid}:show`, () => {
      this.active = !0;
    }), this.emitter.on("modal:*:hide", () => {
      this.active = !1;
    });
  },
  computed: {
    resolvedClasses() {
      var e = {};
      return e[`${this.size}`] = !0, e;
    }
  },
  methods: {
    close(e = !1) {
      (!this.locked || e) && (this.active = !1, this.$emit("close", "close"));
    },
    confirm() {
      this.$emit("action", "confirm");
    },
    cancel() {
      this.$emit("cancel", "cancel");
    }
  }
}), Df = /* @__PURE__ */ U(qc, [["render", Jc]]), xc = { class: "dropdown-wrapper" }, Qc = {
  key: 0,
  class: "dropdown"
}, Xc = ["onClick"], Zc = { class: "trigger" }, ep = ["disabled"];
function tp(e, t, a, s, i, n) {
  return c(), p("div", xc, [
    e.active && !e.disabled ? (c(), p("div", Qc, [
      d("ul", null, [
        (c(!0), p(J, null, Q(e.parsedOptions, (r) => (c(), p("li", {
          onClick: (o) => e.onSelect(r),
          class: N(r.selected ? "selected" : "unselected")
        }, C(r.label), 11, Xc))), 256))
      ])
    ])) : y("", !0),
    d("div", Zc, [
      B(e.$slots, "default", {}, () => [
        e.buttonLabel ? (c(), p("button", {
          key: 0,
          onClick: t[0] || (t[0] = (r) => e.active = !e.active),
          disabled: e.disabled
        }, C(e.buttonLabel), 9, ep)) : y("", !0)
      ])
    ])
  ]);
}
const ap = L({
  props: ["options", "buttonLabel", "context", "disabled", "selected"],
  data() {
    return {
      active: !1
    };
  },
  onClickOut() {
    this.active = !1;
  },
  computed: {
    parsedOptions() {
      return this.options.map((e) => typeof e == "string" || typeof e == "number" ? {
        value: e,
        label: e
      } : e);
    }
  },
  mounted() {
    console.log(" IF SELECTED? : ", this.props.selected);
  },
  methods: {
    onSelect(e) {
      this.active = !1, this.$emit("select", { context: this.context, value: e.value });
    }
  }
}), Ef = /* @__PURE__ */ U(ap, [["render", tp]]), sp = {
  key: 0,
  class: "tag-creator"
}, rp = { class: "controls" }, np = /* @__PURE__ */ d("i", { class: "fas fa-check" }, null, -1), ip = [
  np
], op = /* @__PURE__ */ d("i", { class: "fas fa-times" }, null, -1), lp = [
  op
];
function up(e, t, a, s, i, n) {
  return e.active ? (c(), p("div", sp, [
    V(d("input", {
      "onUpdate:modelValue": t[0] || (t[0] = (r) => e.value = r),
      onBlur: t[1] || (t[1] = (...r) => e.onDone && e.onDone(...r)),
      ref: "input",
      onKeyup: t[2] || (t[2] = ta((...r) => e.onDone && e.onDone(...r), ["enter"]))
    }, null, 544), [
      [W, e.value]
    ]),
    d("div", rp, [
      d("div", {
        class: "icon",
        onClick: t[3] || (t[3] = (...r) => e.onDone && e.onDone(...r))
      }, ip),
      d("div", {
        class: "icon",
        onClick: t[4] || (t[4] = (...r) => e.onCancel && e.onCancel(...r))
      }, lp)
    ])
  ])) : y("", !0);
}
const dp = L({
  props: ["active"],
  data() {
    return {
      value: ""
    };
  },
  watch: {
    active(e, t) {
      e && e !== t && this.$refs.input && this.focus();
    }
  },
  mounted() {
    this.active && this.$refs.input && this.focus();
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    },
    onDone() {
      if (this.value && this.value.length > 1) {
        var e = this.value;
        this.value = "", this.$emit("done", e);
      }
    },
    onCancel() {
      this.value = "", this.$emit("cancel");
    }
  }
}), Mf = /* @__PURE__ */ U(dp, [["render", up]]), cp = { class: "tag-list-editor" }, pp = { class: "tag-list" }, fp = { class: "tag" }, hp = ["onClick"], vp = /* @__PURE__ */ d("div", { class: "icon" }, [
  /* @__PURE__ */ d("i", { class: "fas fa-times" })
], -1), gp = [
  vp
], mp = {
  key: 0,
  class: "add-tag"
}, yp = /* @__PURE__ */ d("div", { class: "icon" }, [
  /* @__PURE__ */ d("i", { class: "fas fa-plus" })
], -1);
function bp(e, t, a, s, i, n) {
  const r = aa("tag-creator");
  return c(), p("div", cp, [
    d("ul", pp, [
      (c(!0), p(J, null, Q(e.tags, (o) => (c(), p("li", fp, [
        d("label", null, C(e.getLabel(o)), 1),
        e.disabled ? y("", !0) : (c(), p("button", {
          key: 0,
          onClick: (l) => e.deleteTag(o)
        }, gp, 8, hp))
      ]))), 256)),
      d("li", null, [
        oe(r, {
          onDone: e.addTag,
          onCancel: t[0] || (t[0] = (o) => e.addingNewTag = !1),
          active: e.addingNewTag
        }, null, 8, ["onDone", "active"])
      ]),
      e.addingNewTag ? y("", !0) : (c(), p("li", mp, [
        e.disabled ? y("", !0) : (c(), p("button", {
          key: 0,
          onClick: t[1] || (t[1] = (...o) => e.startNewKeyword && e.startNewKeyword(...o))
        }, [
          yp,
          d("span", null, C(e.addLabel), 1)
        ]))
      ]))
    ])
  ]);
}
const $p = L({
  props: {
    addLabel: {
      default: "Add",
      type: String
    },
    tags: {
      type: Array,
      default: []
    },
    displayField: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: !1
    }
  },
  data() {
    return {
      addingNewTag: !1
    };
  },
  methods: {
    startNewKeyword() {
      this.addingNewTag = !0;
    },
    addTag(e) {
      this.addingNewTag = !1, this.tags.push(e), this.$emit("change", this.tags);
    },
    deleteTag(e) {
      var t = -1;
      for (let a = 0; a < this.tags.length; a++)
        if (e === this.tags[a]) {
          t = a;
          break;
        }
      t >= 0 && (this.tags.splice(t, 1), this.$emit("change", this.tags));
    },
    getLabel(e) {
      return this.displayField ? e[this.displayField] : e;
    }
  }
}), Lf = /* @__PURE__ */ U($p, [["render", bp]]), _p = {
  key: 0,
  class: "snackbar-container"
}, wp = { class: "snackbar-message" }, Ff = /* @__PURE__ */ L({
  __name: "snackbar",
  props: {
    active: { type: Boolean, default: !1 },
    message: { default: "" },
    secondsActive: { default: 3 }
  },
  setup(e) {
    const t = e, a = g(!1);
    return je(() => t.active, () => {
      a.value = !0, setTimeout(() => {
        a.value = !1;
      }, t.secondsActive * 1e3);
    }), (s, i) => a.value ? (c(), p("div", _p, [
      d("div", wp, C(s.message), 1)
    ])) : y("", !0);
  }
});
const Tp = { class: "v-checkbox-container" }, kp = {
  key: 0,
  class: "icon"
}, Cp = /* @__PURE__ */ d("i", { class: "fas fa-check" }, null, -1), Sp = [
  Cp
];
function Ap(e, t, a, s, i, n) {
  return c(), p("div", Tp, [
    d("div", {
      class: N(["checkbox", e.classes]),
      onClick: t[0] || (t[0] = (r) => e.handleClick(!e.checked))
    }, [
      e.checked ? (c(), p("div", kp, Sp)) : y("", !0)
    ], 2)
  ]);
}
const Ip = L({
  props: {
    checked: { type: Boolean, default: !1 },
    background: { type: String, default: "#FFA502" },
    disabled: { type: Boolean, default: !1 }
  },
  computed: {
    classes() {
      var e = {};
      return this.disabled && (e.disabled = !0), this.checked ? e.checked = !0 : e.outline = !0, e;
    }
  },
  methods: {
    handleClick(e) {
      this.disabled || this.$emit("action", e);
    }
  }
}), Nf = /* @__PURE__ */ U(Ip, [["render", Ap]]), Pp = { class: "radioset" }, Op = { class: "radioset-label" }, jp = { class: "radioset-option" }, Dp = ["id", "value", "disabled"], Ep = ["for"];
function Mp(e, t, a, s, i, n) {
  return c(), p("div", Pp, [
    d("div", Op, C(e.label) + ":", 1),
    d("div", {
      class: N(["radioset-options", { row: e.row }])
    }, [
      (c(!0), p(J, null, Q(e.options, (r, o) => (c(), p("div", jp, [
        V(d("input", {
          class: "radio",
          type: "radio",
          id: o,
          value: r.value,
          disabled: e.disabled || r.disabled,
          "onUpdate:modelValue": t[0] || (t[0] = (l) => e.selectedOption = l)
        }, null, 8, Dp), [
          [sa, e.selectedOption]
        ]),
        d("label", {
          class: "label",
          for: o
        }, C(r.label), 9, Ep)
      ]))), 256))
    ], 2)
  ]);
}
const Lp = L({
  props: {
    label: {
      type: String,
      default: "Please Select an Option"
    },
    options: {
      type: Array,
      validator: (e) => e.every((t) => {
        let a = Object.keys(t);
        return typeof t == "object" && a.length >= 2 && a.length <= 3 && a.includes("label") && a.includes("value");
      })
    },
    disabled: Boolean,
    row: Boolean,
    modelValue: {
      default: null
    }
  },
  emits: ["update:modelValue"],
  setup(e, { emit: t }) {
    const a = g(e.modelValue);
    return je(() => a.value, (s, i) => {
      t("update:modelValue", a.value);
    }), {
      selectedOption: a
    };
  }
}), Rf = /* @__PURE__ */ U(Lp, [["render", Mp]]);
const Fp = { class: "twoPointSlider" }, Np = { class: "values" }, Rp = { class: "range1" }, Vp = { class: "range2" }, Up = { class: "slider-container" }, Bp = /* @__PURE__ */ d("div", { class: "start" }, null, -1), Gp = ["min", "max", "oninput", "step"], Wp = ["min", "max", "oninput", "step"], Kp = /* @__PURE__ */ d("div", { class: "end" }, null, -1), Vf = {
  __name: "twoPointSlider",
  props: {
    min: String,
    max: String,
    minGap: Number,
    inputType: String,
    incrementBy: Number,
    color: String,
    modelValue: {
      type: Object
    }
  },
  emits: ["update:modelValue"],
  setup(e, { emit: t }) {
    const a = e, s = g(0), i = g(100), n = g(a.min), r = g(a.max), o = g(), l = g(), u = g("#DA3A34");
    document.querySelector(".slider-track");
    const h = g(!0), m = g(!0), S = g(0.5), w = g({ start: a.min, end: a.max });
    a.modelValue && (w.value = a.modelValue), H(() => {
      if (a.inputType == "time" ? (s.value = T(a.min), i.value = T(a.max)) : (s.value = a.min, i.value = a.max), a.modelValue) {
        let P, O;
        a.inputType == "time" ? (P = T(w.value.start), O = T(w.value.end)) : (P = w.value.start, O = w.value.end), n.value = w.value.start, o.value = P, r.value = w.value.end, l.value = O;
      } else
        o.value = s.value, l.value = i.value;
      a.incrementBy && (S.value = a.incrementBy), a.color && (u.value = a.color);
    });
    const D = (P) => P - Math.floor(P) !== 0, T = (P) => {
      const O = P.split(":"), F = O[1].split(" ");
      let R = F[1], $ = parseFloat(O[0]), _ = F[0] / 60;
      return R == "am" && $ == 12 && ($ = 0), R == "pm" && $ < 12 && ($ += 12), $ + _;
    }, j = (P) => {
      let O = !1, F = D(P);
      P < 12 && (O = !0, P < 1 && (P += 12)), P >= 12 && (P -= 12);
      let R = P.toString();
      F || (R = `${R}.00`);
      const $ = R.split(".");
      let _ = $[0], v = $[1];
      v = parseFloat(v), v = v * 60 / 100, v.toString().length == 1 && (v = `${v}0`);
      let f = `${_}:${v}`;
      if (O ? f = `${f} am` : f = `${f} pm`, f.includes(".") && (f = f.replace(".", "")), f.includes("pm")) {
        let b = f.split(":");
        b[0] == "0" && (b[0] = "12"), f = `${b[0]}:${b[1]}`;
      }
      return f;
    };
    let E = () => {
      l.value - o.value <= a.minGap && (o.value = parseFloat(l.value) - a.minGap), o.value == s.value ? h.value = !0 : h.value = !1, a.inputType == "time" ? n.value = j(o.value) : n.value = o.value, w.value.start = n.value, t("update:modelValue", w);
    }, Z = () => {
      l.value - o.value <= a.minGap && (l.value = parseFloat(o.value) + a.minGap), l.value == i.value ? m.value = !0 : m.value = !1, a.inputType == "time" ? r.value = j(l.value) : r.value = l.value, w.value.end = r.value, t("update:modelValue", w);
    }, q = G(() => {
      let P = (o.value - s.value) / (i.value - s.value) * 100, O = (l.value - s.value) / (i.value - s.value) * 100;
      return `linear-gradient(to right, #dadae5 ${P}% , ${u.value} ${P}% , ${u.value} ${O}%, #dadae5 ${O}%)`;
    });
    return (P, O) => (c(), p("div", Fp, [
      d("div", Np, [
        d("span", Rp, C(n.value), 1),
        d("span", Vp, C(r.value), 1)
      ]),
      d("div", Up, [
        Bp,
        d("div", {
          class: "slider-track",
          style: mt({ background: M(q) })
        }, null, 4),
        V(d("input", {
          class: "left-slider slider",
          type: "range",
          min: s.value,
          max: i.value,
          "onUpdate:modelValue": O[0] || (O[0] = (F) => o.value = F),
          oninput: M(E),
          step: S.value
        }, null, 8, Gp), [
          [W, o.value]
        ]),
        V(d("input", {
          class: "right-slider slider",
          type: "range",
          min: s.value,
          max: i.value,
          "onUpdate:modelValue": O[1] || (O[1] = (F) => l.value = F),
          oninput: M(Z),
          step: S.value
        }, null, 8, Wp), [
          [W, l.value]
        ]),
        Kp
      ])
    ]));
  }
}, zp = { class: "custom-input" }, Hp = ["disabled"], Yp = { class: "input-label" };
function Jp(e, t, a, s, i, n) {
  return c(), p("div", zp, [
    V(d("input", {
      class: "input",
      id: "name",
      "onUpdate:modelValue": t[0] || (t[0] = (r) => e.inputField = r),
      type: '"text"',
      placeholder: " ",
      required: "",
      disabled: e.disabled,
      onInput: t[1] || (t[1] = (...r) => e.updateValue && e.updateValue(...r))
    }, null, 40, Hp), [
      [W, e.inputField]
    ]),
    d("label", Yp, C(e.label), 1)
  ]);
}
const qp = L({
  props: {
    label: String,
    disabled: Boolean,
    modelValue: String
  },
  emits: ["update:modelValue"],
  setup(e, { emit: t }) {
    const a = g("");
    return H(() => {
      a.value = e.modelValue;
    }), je(() => e.modelValue, () => {
      a.value = e.modelValue;
    }), {
      inputField: a,
      updateValue: (i) => {
        t("update:modelValue", i.target.value);
      }
    };
  }
}), Uf = /* @__PURE__ */ U(qp, [["render", Jp]]), xp = { class: "date-picker" }, Qp = { class: "top-container" }, Xp = /* @__PURE__ */ d("div", { class: "title" }, "SELECT DATE", -1), Zp = { class: "selected-date" }, ef = { class: "bottom-container" }, tf = { class: "month-select" }, af = { class: "month" }, sf = { class: "dates-container" }, rf = { class: "dayOfWeek" }, nf = ["onClick"], Bf = /* @__PURE__ */ L({
  __name: "date-picker",
  props: {
    popup: { type: Boolean },
    disabledDates: {},
    disabledDayOfWeek: {}
  },
  emits: ["close"],
  setup(e, { emit: t }) {
    const a = e, s = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"], i = ["S", "M", "T", "W", "T", "F", "S"], n = g([]), r = /* @__PURE__ */ new Date(), o = g(r.getFullYear()), l = g(r.getMonth()), u = g(l.value), h = g(o.value), m = g(r.getDate()), S = g(r);
    let w = g(r.toDateString()), D = "<", T = ">";
    H(() => {
      j();
    });
    const j = () => {
      var $;
      if (a.disabledDates)
        for (let _ = 0; _ < (($ = a.disabledDates) == null ? void 0 : $.length); _++)
          if (typeof a.disabledDates[_] == "object") {
            let v = new Date(a.disabledDates[_]);
            console.log("Date", v);
            let f = v.getDate(), b = v.getMonth(), z = v.getFullYear(), Te = {
              day: f,
              month: b,
              year: z
            };
            n.value.push(Te);
          } else if (typeof a.disabledDates[_] == "string")
            if (a.disabledDates[_].includes("T")) {
              let v = new Date(a.disabledDates[_]), f = v.getDate();
              console.log(f);
              let b = v.getMonth(), z = v.getFullYear(), Te = {
                day: f,
                month: b,
                year: z
              };
              n.value.push(Te);
            } else {
              let v = a.disabledDates[_].split("/"), f = {
                day: parseInt(v[1]),
                month: parseInt(v[0]),
                year: parseInt(v[2])
              };
              n.value.push(f);
            }
          else
            console.log(`${a.disabledDates[_]} is not supported please look at documentation for supported values.`);
      return !1;
    }, E = ($, _, v) => {
      let f = new Date($, _, v);
      S.value = f, O(v);
      let b = {
        date: S.value,
        text: w.value
      };
      t("close", b);
    }, Z = ($, _) => new Date($, _ + 1, 0).getDate(), q = async ($) => {
      u.value = u.value + $, u.value > 11 ? (u.value = 1, h.value += 1) : u.value <= -1 && (u.value = 11, h.value -= 1), console.log(s[u.value], u.value);
    }, P = ($, _, v) => new Date($, _, v).getDay(), O = ($) => {
      m.value = $, o.value = h.value, l.value = u.value, w.value = S.value.toDateString();
    }, F = ($, _, v) => $ === m.value && _ === l.value && v === o.value, R = ($, _, v) => {
      var b;
      let f = new Date($, _, v).toLocaleString("default", { weekday: "long" });
      if ((b = a.disabledDayOfWeek) != null && b.includes(f))
        return !0;
      if (n.value.length > 0) {
        for (let z = 0; z < n.value.length; z++)
          if (n.value[z].day === v && n.value[z].month === _ && n.value[z].year === $)
            return !0;
      }
      return !1;
    };
    return ($, _) => (c(), p("div", {
      class: N(["date-picker-container", { popup: $.popup, embeded: !$.popup }])
    }, [
      d("div", xp, [
        d("div", Qp, [
          Xp,
          d("div", Zp, C(M(w)), 1)
        ]),
        d("div", ef, [
          d("div", tf, [
            d("span", {
              class: "left-chevy",
              onClick: _[0] || (_[0] = (v) => q(-1))
            }, C(M(D)), 1),
            d("div", af, C(s[u.value]), 1),
            d("span", {
              class: "right-chevy",
              onClick: _[1] || (_[1] = (v) => q(1))
            }, C(M(T)), 1)
          ]),
          d("div", sf, [
            (c(), p(J, null, Q(i, (v) => d("div", rf, C(v), 1)), 64)),
            (c(!0), p(J, null, Q(Z(h.value, u.value), (v) => (c(), p("div", {
              class: N(["days", { selected: F(v, u.value, h.value), disabled: R(h.value, u.value, v) }]),
              style: mt({ "--column": P(h.value, u.value, v) + 1 }),
              onClick: (f) => !R(h.value, u.value, v) && E(h.value, u.value, v)
            }, C(v), 15, nf))), 256))
          ])
        ])
      ])
    ], 2));
  }
});
export {
  Pf as AuthComponent,
  Nf as Checkbox,
  bf as ConditionalRouting,
  Bf as DatePicker,
  da as DefaultConditions,
  Ef as Dropdown,
  vt as FeatureFlags,
  Of as FireInput,
  jf as FireRepeater,
  ic as LoginComponent,
  Oe as LoopTracker,
  Tf as MissingRoutes,
  Df as ModalComponent,
  kf as Paginator,
  yf as PlatformPlugin,
  Cf as ProgressTracker,
  Rf as Radioset,
  bc as ResetCredentialsComponent,
  Sf as RouterLoopProtection,
  vc as SignupComponent,
  Ff as SnackBar,
  Mf as TagCreator,
  Lf as TagListEditor,
  Uf as TextField,
  Vf as TwoPointSlider,
  If as VueFireObjectsPlugin,
  Df as VueModal,
  Wt as WorkWatcher,
  xd as getEmitter,
  wf as initEvents,
  Qd as newId,
  mf as normalizeListItems,
  qd as setEmitter,
  Hd as shouldShowForPlatform,
  Ut as usStates,
  $f as usStatesAbbrivOnly,
  _f as usStatesTerrAbbrivOnly,
  Jd as usStatesTerritories,
  Yd as usTerritories,
  De as useAuth,
  ff as useClickOutside,
  gf as useFirestoreQuery,
  vf as useMittEvent,
  hf as useScreenGeometry,
  Af as useTeleportAwait
};
